import { autoserialize, autoserializeAs } from 'cerialize';
import { IAddress } from './address';
import { BaseDonationLead, IBaseDonationLead } from './base-donation-lead';
import { Charity, ICharity } from './charity';
import { Contact, IContact } from './contact';
import { DonationCharityState } from './donation-charity-state';
import { DonationContent, IDonationContent } from './donation-content';
import { DonationDonorState } from './donation-donor-state';
import { DonationMeta, IDonationMeta } from './donation-meta';
import { DonationPartnerState } from './donation-partner-state';
import { DonationReview, IDonationReview } from './donation-review';
import { PartOfDay } from './part-of-day';
import { Specification } from './specification';
import { CharityStore, ICharityStore } from './store';
import { ISurvey, Survey } from './survey';
import { ITruck, Truck } from './truck';
import { IXmile, Xmile } from './xmile';
import { IPaymentDetails, PaymentDetails } from './cost-details';

export class Donation extends BaseDonationLead implements IDonation {
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('donation_code') donationCode?: string | null;
  @autoserializeAs('part_of_day') partOfDay: PartOfDay;
  @autoserializeAs('dock_signature') dockSignature?: string | null;
  @autoserializeAs('donor_signature') donorSignature?: string | null;
  @autoserializeAs(Contact, 'dock_contact') dockContact?: Contact | null;
  @autoserializeAs(PaymentDetails, 'discount_payment_details')
  discountPaymentDetails?: PaymentDetails | null;
  @autoserializeAs(PaymentDetails, 'payment_details')
  paymentDetails?: PaymentDetails | null;
  @autoserializeAs(Specification, 'specification_description')
  specificationDescription?: string | null;
  @autoserializeAs('charity_id') charityId?: string | null;
  @autoserializeAs('store_id') storeId?: string | null;
  @autoserializeAs('expires_at') expiresAt?: string | null;
  @autoserializeAs(Charity, 'charity') charity?: Charity | null;
  @autoserializeAs('partner_id') partnerId?: string | null;
  @autoserializeAs('partner_state') partnerState: DonationPartnerState;
  @autoserializeAs('charity_state') charityState: DonationCharityState;
  @autoserializeAs('donor_state') donorState: DonationDonorState;
  @autoserializeAs('vehicle_id') vehicleId?: string | null;
  @autoserializeAs(Truck, 'vehicle') vehicle?: Truck | null;
  @autoserializeAs(CharityStore, 'store') store?: CharityStore | null;
  @autoserialize exception: boolean;
  @autoserialize resolved?: boolean | null;
  @autoserializeAs('termination_reason') terminationReason?: string | null;
  @autoserializeAs('termination_note') terminationNote?: string | null;
  @autoserializeAs('donation_description') donationDescription?: string | null;
  @autoserializeAs('accepted_content') acceptedContent?:
    | DonationContent[]
    | null;
  @autoserializeAs('donation_notes') donationNotes?: string | null;
  @autoserialize content?: DonationContent[] | null;
  @autoserialize instructions?: string | null;
  @autoserializeAs('marketing_source') marketingSource?: string | null;
  @autoserialize review?: DonationReview | null;
  @autoserialize meta?: DonationMeta | null;
  @autoserializeAs('partner_comment') partnerComment?: string | null;
  @autoserializeAs('route_order') order?: number | null;
  @autoserializeAs('extra_mile_id') extraMileId?: string | null;
  @autoserializeAs('extra_mile') extraMile?: Xmile | null;
  @autoserializeAs('request_eta') requestEta?: boolean | null;
  @autoserializeAs('eta_note') etaNote?: string | null;
  @autoserialize eta?: string | null;
  @autoserialize photos?: string[] | null;
  @autoserializeAs('reschedule_available') rescheduleAvailable?: boolean;
  @autoserializeAs('charity_survey') charitySurvey?: Survey;

  constructor(payload: IDonation) {
    super(payload);
    this.id = payload?.id?.toString();
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.donationCode = payload?.donationCode;
    this.partOfDay = payload?.partOfDay;
    this.dockSignature = payload?.dockSignature;
    this.donorSignature = payload?.donorSignature;
    this.dockContact = payload?.dockContact
      ? new Contact(payload?.dockContact)
      : undefined;
    this.discountPaymentDetails = payload?.discountPaymentDetails
      ? new PaymentDetails(payload?.discountPaymentDetails)
      : undefined;
    this.paymentDetails = payload?.paymentDetails
      ? new PaymentDetails(payload?.paymentDetails)
      : undefined;
    this.charity = payload?.charity ? new Charity(payload?.charity) : undefined;
    this.charityId = payload?.charityId?.toString() || this.charity?.id;
    this.partnerState = payload?.partnerState;
    this.partnerId = payload?.partnerId?.toString() || this?.partner?.id;
    this.donorState = payload?.donorState;
    this.vehicle = payload?.vehicle ? new Truck(payload.vehicle) : undefined;
    this.vehicleId = payload?.vehicleId?.toString() || this.vehicle?.id;
    this.charityState = payload?.charityState;
    this.exception = payload?.exception;
    this.expiresAt = payload?.expiresAt;
    this.terminationReason = payload?.terminationReason;
    this.terminationNote = payload?.terminationNote;
    this.store = payload?.store ? new CharityStore(payload?.store) : undefined;
    this.storeId = payload?.storeId?.toString() || this.store?.id;
    this.donationDescription = payload?.donationDescription;
    this.content = payload?.content?.map((x) => new DonationContent(x));
    this.acceptedContent = payload?.acceptedContent?.map(
      (x) => new DonationContent(x)
    );
    this.specificationDescription = payload?.specificationDescription;
    this.donationNotes = payload?.donationNotes;
    this.instructions = payload?.instructions;
    this.marketingSource = payload?.marketingSource;
    this.resolved = payload?.resolved;
    this.review = payload?.review
      ? new DonationReview(payload?.review)
      : undefined;
    this.partnerComment = payload?.partnerComment;
    this.meta = new DonationMeta(payload?.meta);
    this.order = payload?.order;
    this.extraMile = payload?.extraMile
      ? new Xmile(payload.extraMile)
      : undefined;
    this.extraMileId = payload?.extraMileId?.toString() || this.extraMile?.id;
    this.requestEta = payload?.requestEta;
    this.etaNote = payload?.etaNote;
    this.eta = payload?.eta;
    this.photos = payload?.photos;
    this.rescheduleAvailable = !!payload?.rescheduleAvailable;
    this.charitySurvey = payload?.charitySurvey;
  }
}

export interface IDonation extends IBaseDonationLead {
  createdAt?: string | null;
  updatedAt?: string | null;
  donationCode?: string | null;
  partOfDay: PartOfDay;
  dockSignature?: string | null;
  donorSignature?: string | null;
  donor: IContact;
  dockContact?: IContact | null;
  discountPaymentDetails?: IPaymentDetails | null;
  paymentDetails?: IPaymentDetails | null;
  address: IAddress;
  charityId?: string | null;
  charity?: ICharity | null;
  partnerId?: string | null;
  partnerState: DonationPartnerState;
  charityState: DonationCharityState;
  donorState: DonationDonorState;
  store?: ICharityStore | null;
  storeId?: string | null;
  vehicleId?: string | null;
  vehicle?: ITruck | null;
  exception: boolean;
  expiresAt?: string | null;
  terminationReason?: string | null;
  terminationNote?: string | null;
  donationDescription?: string | null;
  acceptedContent?: IDonationContent[] | null;
  content?: IDonationContent[] | null;
  gratuity: number;
  specificationDescription?: string | null;
  donationNotes?: string | null;
  instructions?: string | null;
  marketingSource?: string | null;
  resolved?: boolean | null;
  review?: IDonationReview | null;
  partnerComment?: string | null;
  meta?: IDonationMeta | null;
  order?: number | null;
  extraMileId?: string | null;
  extraMile?: IXmile | null;
  requestEta?: boolean | null;
  etaNote?: string | null;
  eta?: string | null;
  photos?: string[] | null;
  rescheduleAvailable?: boolean;
  charitySurvey?: ISurvey;
}
