import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Charity, SplitScreen, WindowSize } from '@domains';
import {
  InformationDialogComponent,
  Responsive,
  ResponsiveService,
} from '@rspl-ui';
import {
  CarouselAlignMode,
  CarouselConfig,
  CarouselWidthMode,
} from 'ng-carousel-cdk';
import { takeUntil } from 'rxjs';
import { AboutResupplyDialogComponent } from '../about-resupply-dialog/about-resupply-dialog.component';

declare const zE: any;

@Component({
  selector: 'rspl-split-screen-v3b',
  templateUrl: './split-screen-v3b.component.html',
  styleUrl: './split-screen-v3b.component.scss',
})
export class SplitScreenV3bComponent extends Responsive implements OnInit {
  @Input() charity: Charity;
  @Input() config: SplitScreen;
  @Input() goToPrimary: () => void;
  @Input() goToFree: () => void;

  itemIndex = 0;

  carouselConfig: CarouselConfig<{
    name: string;
    role: string;
    image: string;
    icon: string;
  }> = {
    items: [
      {
        name: 'Kathleen Huntsman',
        role: 'Sr. Customer Success Manager',
        image: 'kathleen-huntsman.jpeg',
        icon: 'air-force',
      },
      {
        name: 'Grace Kiley',
        role: 'Account Manager',
        image: 'grace-kiley.jpeg',
        icon: 'air-force',
      },
      {
        name: 'Nick Gonzalez',
        role: 'Sr. Director Of Operations',
        image: 'nick-gonzalez.jpeg',
        icon: 'army',
      },
      {
        name: 'Paul Tocci',
        role: 'CEO & Founder',
        image: 'paul-tocci.png',
        icon: 'army',
      },
      {
        name: 'Teddy Griffin',
        role: 'Sr. Director Of Customer Success',
        image: 'teddy-griffin.jpeg',
        icon: 'air-force',
      },
      {
        name: 'Daniel Dulay',
        role: 'Donor Support Manager',
        image: 'daniel-dulay.jpeg',
        icon: 'air-force',
      },
      {
        name: 'Matt Hernandez',
        role: 'Enterprise Account Manager',
        image: 'matt-hernandez.jpeg',
        icon: 'army',
      },
      {
        name: 'Josh Bell',
        role: 'Donor Specialist',
        image: 'josh-bell.jpeg',
        icon: 'marine',
      },
      {
        name: 'Brent Bethel',
        role: 'Driver Account Executive',
        image: 'brent-bethel.png',
        icon: 'army',
      },
      {
        name: 'Charles Appleby',
        role: 'Charity Partnerships Manager',
        image: 'charles-appleby.webp',
        icon: 'army',
      },
    ],
    alignMode: CarouselAlignMode.LEFT,
    widthMode: CarouselWidthMode.PX,
    slideWidth: 275,
    shouldLoop: true,
    autoplayEnabled: true,
    autoplayDelay: 5000,
  };

  faqs = [
    {
      question: 'Why is there a fee to donate my items to charity?',
      answer:
        'Through our fee-based all-in-one pickup service, you are covering the costs of the fuel; professionally licensed and insured haulers; possible recycling services; and the transportation needed to find your items a new home—expenses that would otherwise come out of the pockets of our partner charities.',
    },
    {
      question:
        'How is ReSupply’s priority pick-up different from a free charity pickup?',
      answer: `Our veteran-owned and -led team at ReSupply is committed to putting your needs first, alongside those of our charity partners. We know how important your donations are, and while pick-ups are typically an added service for charities, many nonprofits trust us as their exclusive pick-up provider for several key reasons:
      <ol type="a">
      <li>Supports charities: Our partnership is entirely free for all charities. We do not charge charities anything for receiving donations, in order to support their mission</li>
      <li>Real-Time Support: We are here to support you with real-time scheduling and assistance for every donation. Our dedicated team of transitioning military members and veterans is always ready to lend a hand, no matter where you are or when you need us.</li>
      <li>Automated Tax Receipts: We ensure you receive tax receipts quickly and effortlessly. While charities usually handle their own tax receipts, our automated electronic distribution saves our partners the monetary and environmental costs of paper receipts. </li>
      <li>Quick Pick-Ups: We can schedule pickups as little as one day's notice. While free charity pickups often have wait times up to 6 weeks due to the high demand, ReSupply ensures your donation is picked up within 24-72 hours to accommodate your urgent needs. </li>
      <li>Handling Complex Situations: Our professional haulers are here to take the hard work off of your hands. Whether it is disassembling items or navigating stairs, we've got it covered. We carefully manage our workload to ensure that even complex, multi-story pickups are handled smoothly and efficiently, so you don’t have to lift a finger. </li>
      </ol>`,
    },
    {
      question: 'Will you pick up all of my items?',
      answer:
        'Absolutely! We try to pick up any and all of your items regardless of their condition. While our partner charities strive to accept everything that supports their mission, they can’t always take every item. If that is the case, we encourage our haulers to complete your pickup if possible, even if it requires multiple stops at additional charities. </br></br>In rare cases, if any items cannot be donated to any of our partnered charities, we will ensure they are recycled or ethically disposed of. Please note that there may be additional fees for any items that are recycled or disposed of.',
    },
    {
      question: 'Will I receive a tax receipt for my donation?',
      answer:
        'Yes! Once your items have been successfully donated to the charity, you will receive a link from ReSupply to your electronic tax receipt. You can print the receipt or download it to your computer for your records. If you have any questions about filling out the tax receipt, do not hesitate to contact our ReSupply support team! ',
    },
  ];

  allReviews = [
    [
      {
        name: 'William',
        stars: 5,
        review: `We couldn't be more pleased with the service we received today from Vasily. We had 16 items to donate spread over 3 floors of our house. Vasily arrived earlier than anticipated, reviewed each item and clearly explained how some items were headed for recycling instead of donation due to their wear. I will certainly contact Resupply again should I have items that could see new life with a needy family.`,
        date: '2024.10.20',
        icon: 'ss3-google',
      },
      {
        name: 'Sue',
        stars: 5,
        review: `The pick up team arrived promptly after we received the expected text...they were courteous, friendly and just plain "nice" ..out items were removed quickly, no issues...<br/>I would recommend them to anyone looking to donate....`,
        date: '2024.10.17',
        icon: 'ss3-google',
      },
      {
        name: 'Jim',
        stars: 5,
        review: `The team was terrific. We had lots of furniture to move out, but they came in, jumped in with a can-do attitude, and gladly corrected a slight misunderstanding of what we wanted done. All with a smile. Great.`,
      },
    ],
    [
      {
        name: 'Marcia',
        stars: 5,
        review: `They looked everything over and gave me the final price upfront. It was pretty much the same as what I had gotten online, increased a tad as I added a piece to it. They were on time within the window I was expecting. Super nice and efficient.`,
        date: '2024.10.17',
        icon: 'ss3-google',
      },
      {
        name: 'Helen ',
        stars: 5,
        review: `Very professional and respectful movers. The movers were very professional and efficient. They worked fast but carefully and were very respectful of our home and family. The lead mover also disassembled a table that was too big to go through a door, which I had not anticipated, and was cheerful about itl Thanks for providing such careful service!`,
        date: '2024.10.17',
        icon: 'ss3-check',
      },
      {
        name: 'Nancy',
        stars: 4,
        review: `Fermentum, consectetur nam magnis viverra suspendisse quam condimentum varius.`,
      },
    ],
  ];

  reviews = this.allReviews;

  constructor(
    public override responsiveService: ResponsiveService,
    private dialog: MatDialog
  ) {
    super(responsiveService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.responsiveService.size
      .pipe(takeUntil(this.destroy$))
      .subscribe((size) => {
        this.carouselConfig = {
          ...this.carouselConfig,
          alignMode: [WindowSize.LG, WindowSize.XL, WindowSize.MD].includes(
            size
          )
            ? CarouselAlignMode.LEFT
            : CarouselAlignMode.CENTER,
        };
        this.reviews = [WindowSize.LG, WindowSize.XL, WindowSize.MD].includes(
          size
        )
          ? this.allReviews
          : [this.allReviews[0]];
      });
  }

  setItemIndex(newIndex: number): void {
    this.itemIndex = newIndex;
  }

  get shortText() {
    return (
      this.isSmallWindowSize && this.config?.text?.length > 500
        ? this.config?.text?.substring(0, 500) + '...'
        : this.config?.text
    ).replaceAll('\n', '</br>');
  }

  get text() {
    return this.config?.text?.replaceAll('\n', '</br>');
  }

  showText() {
    this.dialog.open(InformationDialogComponent, {
      width: '90vw',
      height: '80vh',
      data: {
        title: this.config.title,
        message: this.config.text?.replaceAll('\n', '</br>'),
        button: 'I understand',
      },
    });
  }

  openZendesk() {
    if (typeof zE !== 'undefined') {
      zE('messenger', 'open');
    }
  }

  showVideo() {
    this.dialog.open(AboutResupplyDialogComponent, {
      width: '690px',
      maxWidth: '90vw',
      maxHeight: '80vh',
    });
  }
}
