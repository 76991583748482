import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Address, ENVIRONMENT, Environment, Place } from '@domains';
import {
  DesignService,
  Designable,
  ResponsiveService,
  UsStatesSelectorComponent,
  states,
} from '@rspl-ui';
import { take } from 'rxjs';

@Component({
  selector: 'rspl-address-input-form',
  templateUrl: './address-input-form.component.html',
  styleUrls: ['./address-input-form.component.scss'],
})
export class AddressInputFormComponent extends Designable {
  @ViewChild('addressDialog') addressDialogTemplate!: TemplateRef<any>;
  @ViewChild('inputElement') inputEl: any;
  @ViewChildren('li') addrLis: any[] = [];
  @Output() addressChange: EventEmitter<{ address: Address; manual: boolean }> =
    new EventEmitter<{ address: Address; manual: boolean }>();
  @Output() addressError: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddressPopupOpened: EventEmitter<void> = new EventEmitter<void>();
  @Input() label = 'Your address';
  @Input() readonly = false;
  @Input() showError = false;
  @Input() leadId?: string;
  @Input() types: string[] = ['address'];
  @Input() set disabled(disabled: boolean) {
    if (disabled) this.form.disable();
    else this.form.enable();
  }
  #stateSelect?: UsStatesSelectorComponent;
  @ViewChild(UsStatesSelectorComponent) set stateSelect(
    stateSelect: UsStatesSelectorComponent | undefined
  ) {
    this.#stateSelect = stateSelect;
    if (this.form.get('city')?.touched) {
      this.stateSelect?.stateControl.markAsTouched();
      this.stateSelect?.stateControl.updateValueAndValidity();
    }
  }

  get stateSelect(): UsStatesSelectorComponent | undefined {
    return this.#stateSelect;
  }
  savedAddress?: Address;
  delay: any;
  mapBoxAccessToken: string;

  addressDialog?: MatDialogRef<any>;

  showForm = false;
  isManualAddress = false;

  form = new FormGroup({
    street: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^([nswe]\d+[nswe]\d+|\d+[a-zA-Z]{0,2})\s[\w\-]+(\s[\w\-]+)*(\s[\w&'\-]+)?$/),
    ]),
    secondary: new FormControl<string>(''),
    city: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^[\w\s\-\.']+$/),
    ]),
    state: new FormControl<string>('', [Validators.required]),
    zip: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^\d{5}(-\d{4}){0,1}$/),
    ]),
    lat: new FormControl<number | null>(null),
    lng: new FormControl<number | null>(null),
  });

  @Input() set address(address: Address | undefined) {
    if (address && (address?.street?.length || 0) > 0) {
      if (
        (address.street?.length || 0) > 0 &&
        address.street !== this.address.street
      ) {
        this.getAddresses(address.street);
      } else {
      }
      this.form.patchValue(address);
      if (address.zip) {
        this.showForm = true;
      }
      this.savedAddress = { ...address } as Address;
    }
  }

  get address(): Address {
    return this.form.getRawValue() as Address;
  }

  addresses: Address[] = [];
  loading = false;

  constructor(
    private dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient
  ) {
    super(designService, responsiveService);
    this.mapBoxAccessToken = this.config.mapBoxAccessToken;
  }

  openAddress() {
    this.addressDialog = this.dialog.open(this.addressDialogTemplate, {
      width: '100vw',
      maxWidth: '600px',
      maxHeight: '100vh',
      panelClass: 'address-input-form-dialog',
    });
    this.onAddressPopupOpened.emit();
    this.addressDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (this.savedAddress)
          this.address = { ...this.savedAddress } as Address;
      });
  }

  getAddressesDelayed() {
    this.isManualAddress = true;
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      this.getAddresses(this.address.street);
    }, 300);
  }

  getAddresses(street: string | undefined | null) {
    this.addresses = [];
    if (street?.trim().length === 0) return;
    this.loading = true;

    this.http
      .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${street}.json`, {
        params: {
          autocomplete: true,
          country: 'us',
          language: 'en',
          limit: 10,
          proximity: 'ip',
          types: this.types.join(','),
          access_token: this.config.mapBoxAccessToken,
        },
      })
      .pipe(take(1))
      .subscribe((res: { features?: Place[] }) => {
        this.addresses = [];
        res.features?.map((place) =>
          this.addresses.push({
            street: (place.address ? place.address + ' ' : '') + (place.text_en || place.text),
            city: place.context.find((c) => c.id.includes('place'))?.text_en || place.context.find((c) => c.id.includes('place'))?.text,
            state: place.context
              .find((c) => c.id.includes('region'))
              ?.short_code.replace('US-', ''),
            zip: place.context.find((c) => c.id.includes('postcode'))?.text_en || place.context.find((c) => c.id.includes('postcode'))?.text,
            lat: place.center[1],
            lng: place.center[0],
          } as Address)
        );
        this.loading = false;
      });
  }

  formatAddress(address?: Address): string {
    return address && (address?.street?.length || 0) > 0
      ? `${address.street || ''}${
          address.secondary ? ' #' + address.secondary : ''
        } ${address.city || ''}, ${address.state || ''} ${
          address.zip || ''
        }, USA`
      : '';
  }

  select(
    addr: Address,
    event: MatOptionSelectionChange
  ) {
    if (!event.isUserInput) return;
    this.showForm = true;
    this.isManualAddress = false;
    this.form.patchValue(addr);
  }

  confirmAddress() {
    this.form.markAsTouched();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.stateSelect?.stateControl.markAsTouched();
    this.stateSelect?.stateControl.updateValueAndValidity();
    if (this.form.invalid) {
      this.setAddressError();
      return;
    }

    this.addressChange.emit({
      address: new Address(this.address),
      manual: this.isManualAddress,
    });
    this.addressDialog?.close();
  }

  setAddressError() {
    this.addressError.emit(this.formatAddress(this.address));
  }

  formatState(state?: string): string {
    return state
      ? states.find((s) => s.abbreviation === state)?.name || '/'
      : '/';
  }

  get fullAddress() {
    return this.savedAddress
      ? `${this.savedAddress.street || ''}${
          this.savedAddress.secondary ? ' #' + this.savedAddress.secondary : ''
        } ${this.savedAddress.city || ''}, ${this.savedAddress.state || ''} ${
          this.savedAddress.zip || ''
        }, USA`
      : '';
  }

  public setSelectedState(e: { name: string; abbreviation: string }): void {
    this.isManualAddress = true;
    this.form.get('state')?.patchValue(e.abbreviation);
  }
}
