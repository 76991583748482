import { autoserializeAs } from "cerialize";

export enum Permissions {
  USER_PERMISSIONS = 'userPermissions',
  LEAD_SETUP_COMPLETED = 'leadSetupCompleted',
  PHONE_CONFIGURATIONS = 'phoneConfigurations',
  MARKET_PRICING2 = 'marketPricing2',
  ITEM_APPROVAL = 'itemApproval',
  SPONSORSHIPS = 'sponsorships',
}

export class UserPermissions implements IUserPermissions {
  @autoserializeAs('user_permissions') userPermissions: boolean;
  @autoserializeAs('lead_setup_completed') leadSetupCompleted: boolean;
  @autoserializeAs('phone_configurations') phoneConfigurations: boolean;
  @autoserializeAs('app_configuration') appConfiguration: boolean;
  @autoserializeAs('market_pricing2') marketPricing2: boolean;
  @autoserializeAs('item_approval') itemApproval: boolean;
  @autoserializeAs('sponsorships') sponsorships: boolean;


  constructor(payload?: IUserPermissions) {
    this.userPermissions = payload?.userPermissions;
    this.leadSetupCompleted = payload?.leadSetupCompleted;
    this.phoneConfigurations = payload?.phoneConfigurations;
    this.appConfiguration = payload?.appConfiguration;
    this.marketPricing2 = payload?.marketPricing2;
    this.itemApproval = payload?.itemApproval;
    this.sponsorships = payload?.sponsorships;
  }

}

export interface IUserPermissions {
  userPermissions: boolean;
  leadSetupCompleted: boolean;
  phoneConfigurations: boolean;
  appConfiguration: boolean;
  marketPricing2: boolean;
  itemApproval: boolean;
  sponsorships: boolean;
}
