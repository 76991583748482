import { autoserialize, autoserializeAs } from 'cerialize';
import { BaseDTO, IBaseDTO } from './base-dto';
import { ITransfer, Transfer, TransferType } from './transfer';

export class Budget extends BaseDTO implements IBudget {
  @autoserialize id?: string;
  @autoserializeAs('sponsorship_id') sponsorshipId?: string | null;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserialize month?: number;
  @autoserialize year?: number;
  @autoserializeAs('stripe_payment_intent') stripePaymentIntent?: string | null;
  @autoserializeAs('stripe_payment_status') stripePaymentStatus?: string | null;
  @autoserializeAs('stripe_payment_amount') stripePaymentAmount?: number;
  @autoserializeAs('stripe_payment_method_type') stripePaymentMethodType?: string | null;
  @autoserializeAs('stripe_payment_receipt_url') stripePaymentReceiptUrl?: string | null;
  @autoserialize balance?: number;
  @autoserialize reserved?: number;
  @autoserialize transfers: Array<Transfer>;

  monthName: string;
  pendingMap: { [key: string]: number } = {};
  pendingBudget = 0;
  completedMap: { [key: string]: number } = {};
  completedBudget = 0;
  donationIds: string[] = [];

  constructor(payload?: IBudget) {
    super(payload);
    this.id = payload?.id?.toString();
    this.sponsorshipId = payload?.sponsorshipId?.toString();
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.discardedAt = payload?.discardedAt;
    this.month = payload?.month;
    this.year = payload?.year;
    this.stripePaymentIntent = payload?.stripePaymentIntent;
    this.stripePaymentStatus = payload?.stripePaymentStatus;
    this.stripePaymentAmount = payload?.stripePaymentAmount;
    this.stripePaymentMethodType = payload?.stripePaymentMethodType;
    this.stripePaymentReceiptUrl = payload?.stripePaymentReceiptUrl;
    this.balance = payload?.balance;
    this.reserved = payload?.reserved;
    this.transfers = payload?.transfers
      ? payload.transfers.map((t) => new Transfer(t))
      : [];
    this.transfers.forEach((t) => {
      if (t.type == TransferType.AUTHORIZATION) {
        this.pendingMap[t.donationId] = t.amount;
      }
      if (t.type === TransferType.COMPLETE) {
        delete this.pendingMap[t.donationId];
        this.completedMap[t.donationId] = t.amount;
      }

      if (t.type == TransferType.RELEASE) {
        delete this.pendingMap[t.donationId];
      }
      if (t.type == TransferType.RELEASE) {
        delete this.completedMap[t.donationId];
      }
      if (!this.donationIds.includes(t.donationId)) {
        this.donationIds.push(t.donationId);
      }
    });
    this.completedBudget = Object.values(this.completedMap || {}).reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    this.pendingBudget = Object.values(this.pendingMap || {}).reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    switch (this.month) {
      case 1:
        this.monthName = 'January';
        break;
      case 2:
        this.monthName = 'February';
        break;
      case 3:
        this.monthName = 'March';
        break;
      case 4:
        this.monthName = 'April';
        break;
      case 5:
        this.monthName = 'May';
        break;
      case 6:
        this.monthName = 'June';
        break;
      case 7:
        this.monthName = 'July';
        break;
      case 8:
        this.monthName = 'August';
        break;
      case 9:
        this.monthName = 'September';
        break;
      case 10:
        this.monthName = 'October';
        break;
      case 11:
        this.monthName = 'November';
        break;
      case 12:
        this.monthName = 'December';
        break;
    }
  }
}

export interface IBudget extends IBaseDTO {
  id?: string;
  sponsorshipId?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  discardedAt?: string | null;
  month?: number;
  year?: number;
  stripePaymentIntent?: string | null;
  stripePaymentStatus?: string | null;
  stripePaymentAmount?: number;
  stripePaymentMethodType?: string | null;
  stripePaymentReceiptUrl?: string | null;
  balance?: number;
  reserved?: number;
  transfers?: Array<ITransfer>;
}
