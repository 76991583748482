import { Budget } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './budget.actions';

export interface BudgetState {
  budgets: Array<Budget>;
  budgetsTotalCount: number;
  budget: Budget;
}

const initialState: BudgetState = {
  budgets: new Array<Budget>(),
  budgetsTotalCount: 0,
  budget: new Budget(),
};

const reducer = createReducer(
  initialState,

  on(fromActions.createBudgetSuccess, (state, action: any) => ({...state, budget: action.budget})),
  on(fromActions.updateBudgetSuccess, (state, action: any) => ({...state, budget: action.budget})),

  on(fromActions.getBudgets, (state, {findParams, keep}) => {
    return {...state, budgets: keep ? state.budgets : []};
  }),
  on(fromActions.getBudgetsSuccess, (state, {budgets, resultsCount}) => ({...state, budgets, budgetsTotalCount: resultsCount})),
  on(fromActions.clearBudgetsFindParams, state => {
    return {...state};
  }),

  on(fromActions.getBudget, (state, {id, keep}) => ({...state, budget: keep ? state.budget : null})),
  on(fromActions.getBudgetSuccess, (state, action: any) => ({...state, budget: action.budget})),
);

export function budgetReducer(state: BudgetState | undefined, action: Action) {
  return reducer(state, action);
}
