import { autoserialize } from 'cerialize';
import { DonationReminderTrigger } from './donation-reminder-trigger';
import { DonationSource } from './donation-source';

export class DonationMeta {
  @autoserialize source?: DonationSource;
  @autoserialize reminderState?: DonationReminderTrigger;
  @autoserialize lastReminderSentAt?: string;
  @autoserialize declinedExtraMile?: boolean;
  @autoserialize design?: string;
  @autoserialize manualAddress: boolean;
  @autoserialize requestCancel: boolean;
  @autoserialize etaProvidedAt?: string;
  @autoserialize etaRequestedAt?: string;
  @autoserialize smsApproval?: boolean;
  @autoserialize marketingRule?: string;
  @autoserialize marketingVariation?: string;
  @autoserialize flowRule?: string;
  @autoserialize flowVariation?: string;
  @autoserialize geofence?: 'soft' | 'hard';
  @autoserialize donatableSmall?: number;
  @autoserialize nonDonatableSmall?: number;

  constructor(payload?: IDonationMeta | null) {
    this.source = payload?.source;
    this.reminderState = payload?.reminderState;
    this.lastReminderSentAt = payload?.lastReminderSentAt;
    this.declinedExtraMile = payload?.declinedExtraMile;
    this.design = payload?.design;
    this.manualAddress = !!payload?.manualAddress;
    this.requestCancel = !!payload?.requestCancel;
    this.etaProvidedAt = payload?.etaProvidedAt;
    this.etaRequestedAt = payload?.etaRequestedAt;
    this.smsApproval = payload?.smsApproval;
    this.marketingRule = payload?.marketingRule;
    this.marketingVariation = payload?.marketingVariation;
    this.flowRule = payload?.flowRule;
    this.flowVariation = payload?.flowVariation;
    this.geofence = payload?.geofence;
    this.donatableSmall = payload?.donatableSmall;
    this.nonDonatableSmall = payload?.nonDonatableSmall;
  }
}

export interface IDonationMeta {
  source?: DonationSource;
  reminderState?: DonationReminderTrigger;
  lastReminderSentAt?: string;
  declinedExtraMile?: boolean;
  design?: string;
  manualAddress?: boolean;
  requestCancel?: boolean;
  etaProvidedAt?: string;
  etaRequestedAt?: string;
  smsApproval?: boolean;
  marketingRule?: string;
  marketingVariation?: string;
  flowRule?: string;
  flowVariation?: string;
  geofence?: 'soft' | 'hard';
  donatableSmall?: number;
  nonDonatableSmall?: number;
}
