import { autoserialize } from 'cerialize';

export class Survey {
  @autoserialize reason?: string;
  @autoserialize comment?: string;

  constructor(payload?: ISurvey) {
    this.reason = payload?.reason;
    this.comment = payload?.comment;
  }
}

export interface ISurvey {
  reason?: string;
  comment?: string;
}
