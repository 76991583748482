import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { WindowSize } from '@domains';
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../responsive';

@Directive({
  selector: '[fxFlex]',
})
export class FxFlexDirective implements OnInit, OnDestroy {
  @Input('fxFlex') defaultFlex: string | number; // Default flex value
  @Input('fxFlex.gt-lg') fxFlexGtLg: string | number;
  @Input('fxFlex.gt-md') fxFlexGtMd: string | number;
  @Input('fxFlex.gt-sm') fxFlexGtSm: string | number;
  @Input('fxFlex.gt-xs') fxFlexGtXs: string | number;
  @Input('fxFlex.lt-xl') fxFlexLtXl: string | number;
  @Input('fxFlex.lt-lg') fxFlexLtLg: string | number;
  @Input('fxFlex.lt-md') fxFlexLtMd: string | number;
  @Input('fxFlex.lt-sm') fxFlexLtSm: string | number;
  @Input('fxFlex.md') fxFlexMd: string | number;
  @Input('fxFlex.sm') fxFlexSm: string | number;
  @Input('fxFlex.lg') fxFlexLg: string | number;
  @Input('fxFlex.xl') fxFlexXl: string | number;
  @Input('fxFlex.xs') fxFlexXs: string | number;

  private layoutChanges$: Subscription;
  @HostBinding('style.flex') flexValue: string | number;

  constructor(
    private responsiveService: ResponsiveService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    // Subscribe to layout changes and reapply the correct flex value on breakpoint change
    this.layoutChanges$ = this.responsiveService.size.subscribe((size) => {
      this.applyFlex(size);
    });
  }

  ngOnDestroy() {
    if (this.layoutChanges$) {
      this.layoutChanges$.unsubscribe();
    }
  }

  hasValue(value: string | number): boolean {
    return value !== undefined && value !== null;
  }

  // Apply the correct flex value based on the current breakpoint
  private applyFlex(size: WindowSize) {
    this.flexValue = this.defaultFlex;
    
    if (this.flexValue.toString() !== '0') {
      this.flexValue = this.flexValue || 1;
    }

    if (this.hasValue(this.fxFlexXs) && size === WindowSize.XS) {
      this.flexValue = this.fxFlexXs;
    } else if (this.hasValue(this.fxFlexSm) && size === WindowSize.SM) {
      this.flexValue = this.fxFlexSm;
    } else if (this.hasValue(this.fxFlexMd) && size === WindowSize.MD) {
      this.flexValue = this.fxFlexMd;
    } else if (this.hasValue(this.fxFlexLg) && size === WindowSize.LG) {
      this.flexValue = this.fxFlexLg;
    } else if (this.hasValue(this.fxFlexXl) && size === WindowSize.XL) {
      this.flexValue = this.fxFlexXl;
    }

    // Handle 'greater than' breakpoints (gt-*)
    if (this.hasValue(this.fxFlexGtLg) && [WindowSize.XL].includes(size)) {
      this.flexValue = this.fxFlexGtLg;
    } else if (
      this.hasValue(this.fxFlexGtMd) &&
      [WindowSize.XL, WindowSize.LG].includes(size)
    ) {
      this.flexValue = this.fxFlexGtMd;
    } else if (
      this.hasValue(this.fxFlexGtSm) &&
      [WindowSize.XL, WindowSize.LG, WindowSize.MD].includes(size)
    ) {
      this.flexValue = this.fxFlexGtSm;
    } else if (
      this.hasValue(this.fxFlexGtXs) &&
      [WindowSize.XL, WindowSize.LG, WindowSize.MD, WindowSize.SM].includes(
        size
      )
    ) {
      this.flexValue = this.fxFlexGtXs;
    }

    // Handle 'less than' breakpoints (lt-*)
    if (this.hasValue(this.fxFlexLtSm) && [WindowSize.XS].includes(size)) {
      this.flexValue = this.fxFlexLtSm;
    } else if (
      this.hasValue(this.fxFlexLtMd) &&
      [WindowSize.XS, WindowSize.SM].includes(size)
    ) {
      this.flexValue = this.fxFlexLtMd;
    } else if (
      this.hasValue(this.fxFlexLtLg) &&
      [WindowSize.XS, WindowSize.SM, WindowSize.MD].includes(size)
    ) {
      this.flexValue = this.fxFlexLtLg;
    } else if (
      this.hasValue(this.fxFlexLtXl) &&
      [WindowSize.XS, WindowSize.SM, WindowSize.MD, WindowSize.LG].includes(
        size
      )
    ) {
      this.flexValue = this.fxFlexLtXl;
    }

    if (this.flexValue === null || this.flexValue === undefined) {
      this.flexValue = 1;
    }

    // Apply the flex value to the element
    if (
      this.flexValue.toString() !== '0' &&
      this.flexValue.toString() !== '1'
    ) {
      const parent = this.el.nativeElement.parentElement;
      const gap = parent?.style?.gap || '0px';
      this.flexValue = this.el.nativeElement.parentElement.style.flexFlow === 'wrap' || this.el.nativeElement.nextElementSibling ? `calc(${this.flexValue}% - ${gap})` : `${this.flexValue}%`;
    }
    
    if (this.flexValue.toString() !== '0') {
      this.renderer.setStyle(this.el.nativeElement, 'flex', `1 1 ${this.flexValue}`);
    } else {
      this.flexValue = undefined;
    }
  }
}
