import { autoserialize, autoserializeAs } from 'cerialize';
import { IBaseDTO } from './base-dto';

export enum TransferType {
  AUTHORIZATION = 'authorization',
  COMPLETE = 'complete',
  RELEASE = 'release',
  REFUND = 'refund',
}
export enum TransferSource {
  SUBMIT = 'submit',
  PARTNER_COMPLETE = 'partner_complete',
}

export class Transfer implements ITransfer {
  @autoserializeAs('donation_id') donationId?: string | null;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserialize amount?: number;
  @autoserialize type?: TransferType;
  @autoserialize source?: TransferSource;

  constructor(payload?: ITransfer) {
    this.donationId = payload?.donationId?.toString();
    this.amount = payload?.amount;
    this.createdAt = payload?.createdAt;
    this.type = payload?.type;
    this.source = payload?.source;
  }
}

export interface ITransfer extends IBaseDTO {
  donationId?: string;
  createdAt?: string | null;
  amount?: number;
  type?: TransferType;
  source?: TransferSource;
}
