import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppType,
  Charity,
  Donation,
  ENVIRONMENT,
  Environment,
  Item,
  Lead,
  Pricing,
  Specification,
} from '@domains';
import { Observable, map, take } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog';
import { ItemLookupComponent } from '../lookup';

@Component({
  selector: 'rspl-donation-specification',
  templateUrl: './donation-specification.component.html',
  styleUrls: ['./donation-specification.component.scss'],
})
export class DonationSpecificationComponent implements OnChanges {
  @ViewChild(ItemLookupComponent) itemLookup?: ItemLookupComponent;
  @ViewChild('SponsorshipDialogTemplate') sponsorshipDialogTemplate?: TemplateRef<any>;
  @Input() cardTitle = 'Create the estimate';
  @Input() showErrors = false;
  @Input() pricing?: Pricing;
  #specificationToEdit?: Specification;
  @Input() set specificationToEdit(
    specificationToEdit: Specification | undefined
  ) {
    this.#specificationToEdit = specificationToEdit;
    this.calculateSponsorship();
  }
  get specificationToEdit(): Specification | undefined {
    return this.#specificationToEdit;
  }
  @Output() specificationChanged = new EventEmitter<Specification>();
  @Output() donatableChanged = new EventEmitter<{
    donatableSmall: number;
    nonDonatableSmall: number;
  }>();
  @Output() itemsChanged = new EventEmitter<
    Array<{ item: Item; quantity: number }>
  >();
  @Input() showRecycling = false;
  @Input() showStaircases = true;
  @Input() showElevator = true;
  @Input() showDisassembly = true;
  @Input() showSmall = true;
  @Input() showBasePrice = true;
  @Input() readonly = false;
  @Input() showPrice = false;
  @Input() showPrices = true;
  @Input() showPricing = true;
  @Input() splitSmall = true;
  @Input() errorMessage = 'Quote can not be empty';
  @Input() addNewItem?: (
    name: string,
    quantity: number
  ) => Observable<{ item: Item; quantity: number }>;
  #showItems = false;
  @Input() set showItems(showItems: boolean) {
    this.#showItems = showItems;
    // if (this.showItems) {
    //   this.mode = 'items';
    // } else {
    //   this.mode = 'spec';
    // }
  }
  get showItems(): boolean {
    return this.#showItems;
  }
  #leadOrDonation!: Lead | Donation;
  @Input() set leadOrDonation(leadOrDonation: Lead | Donation) {
    this.#leadOrDonation = leadOrDonation;
    this.calculateSponsorship();
  }

  get leadOrDonation(): Lead | Donation {
    return this.#leadOrDonation;
  }
  @Input() isCalculatingSponsorship = false;
  isCaptain: boolean;
  mode: 'items' | 'spec' = 'spec';
  newItem: { item: Item; quantity: number } = {
    item: null,
    quantity: 1,
  };
  items: Array<{ item: Item; quantity: number }> = new Array<{
    item: Item;
    quantity: number;
  }>();

  specification: Specification = new Specification();
  totalCostEstimate?: number;
  fuelFee?: number;
  sponsoredAmount = 0;

  #donatableSmall = 0;
  @Input() set donatableSmall(donatableSmall: number) {
    this.#donatableSmall = donatableSmall;
    this.calculateSponsorship();
  }
  get donatableSmall(): number {
    return this.#donatableSmall;
  }
  @Input() nonDonatableSmall = 0;
  @Input() isPhoneLead = false;
  @Input() charity: Charity;
  sponsorshipDialog;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private dialog: MatDialog
  ) {
    this.isCaptain = this.config.app === AppType.CAPTAIN;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.specification = this.specificationToEdit
      ? new Specification(this.specificationToEdit)
      : new Specification();
    this.getTotalPrice();
  }

  public addItem(key: string): void {
    (this.specification as any)[key] += 1;
    this.getTotalPrice();
    this.specificationChanged.emit(this.specification);
    this.calculateSponsorship();
  }

  public removeItem(key: string): void {
    (this.specification as any)[key] -= 1;
    this.getTotalPrice();
    this.specificationChanged.emit(this.specification);
    this.calculateSponsorship();
  }

  public updateDonatable(value: 1 | -1): void {
    this.donatableSmall += value;
    this.donatableChanged.emit({
      donatableSmall: this.donatableSmall,
      nonDonatableSmall: this.nonDonatableSmall
    });
  }
  public updateNonDonatable(value: 1 | -1): void {
    this.nonDonatableSmall += value;
    this.donatableChanged.emit({
      donatableSmall: this.donatableSmall,
      nonDonatableSmall: this.nonDonatableSmall
    });
  }

  public getTotalPrice(): void {
    if (this.pricing) {
      this.totalCostEstimate = Pricing.getTotalPrice(
        this.specification,
        this.pricing
      );
      this.fuelFee = this.pricing.fuelFee
        ? (Pricing.getTotalBasePrice(this.specification, this.pricing) *
            this.pricing.fuelFee) /
          100
        : 0;
    }
  }

  addToItems() {
    if (!this.newItem.item || this.newItem.quantity < 1) return;
    const i = this.items.findIndex((x) => x.item.id === this.newItem.item.id);
    if (i >= 0) {
      this.items[i].quantity += this.newItem.quantity;
    } else {
      this.items.push(this.newItem);
    }
    this.specification.large +=
      this.newItem.quantity * this.newItem.item.specification.large;
    this.specification.medium +=
      this.newItem.quantity * this.newItem.item.specification.medium;
    this.specification.xlarge +=
      this.newItem.quantity * this.newItem.item.specification.xlarge;
    this.newItem = {
      item: null,
      quantity: 1,
    };
    this.specificationChanged.emit(this.specification);
    this.itemsChanged.emit(this.items);
    this.itemLookup?.clearSearch();
    (document.querySelector('rspl-item-lookup mat-select') as any)?.focus();
    this.calculateSponsorship();
  }

  removeFromItems(i: number) {
    const old = JSON.stringify(this.specification);
    this.specification.large -=
      this.items[i].quantity * this.items[i].item.specification.large;
    if (this.specification.large < 0) this.specification.large = 0;
    this.specification.medium -=
      this.items[i].quantity * this.items[i].item.specification.medium;
    if (this.specification.medium < 0) this.specification.medium = 0;
    this.specification.xlarge -=
      this.items[i].quantity * this.items[i].item.specification.xlarge;
    if (this.specification.xlarge < 0) this.specification.xlarge = 0;
    console.log('Old:', JSON.parse(old));
    console.log('New:', this.specification);
    this.items.splice(i, 1);
    this.specificationChanged.emit(this.specification);
    this.itemsChanged.emit(this.items);
    this.calculateSponsorship();
  }

  changeMode() {
    if (this.mode === 'spec' && this.items.length > 0)
      this.dialog
        .open(ConfirmDialogComponent, {
          width: '600px',
          maxWidth: '95vw',
          disableClose: true,
          data: {
            title: 'Warning',
            color: 'warn',
            yes: 'Continue',
            no: 'Cancel',
            message:
              'You already started entering the items on the previous screen and the switch to the Spec view could have consequences in quote accuracy and the number of the items entered!',
          },
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((confirmed) => {
          if (!confirmed) {
            this.mode = this.mode === 'items' ? 'spec' : 'items';
          }
        });
  }

  onAddNewItem(name: string) {
    return this.addNewItem(name, this.newItem?.quantity || 0).pipe(
      map((data) => {
        if (data?.item) {
          this.items.push(data);
          this.specification.large +=
            data.quantity * data.item.specification.large;
          this.specification.medium +=
            data.quantity * data.item.specification.medium;
          this.specification.xlarge +=
            data.quantity * data.item.specification.xlarge;
          this.newItem = {
            item: null,
            quantity: 0,
          };
          this.specificationChanged.emit(this.specification);
          this.itemsChanged.emit(this.items);
          this.calculateSponsorship();
          return data.item;
        }
        return null;
      })
    );
  }

  updateItemQuantity(event: any): void {
    const val = event.target.value.replaceAll(/[^\d]/g, '');
    this.newItem.quantity = Math.max(
      1,
      Number(isNaN(val) || val === undefined || val?.length === 0 ? 1 : val)
    );
  }

  focusInput(event: any) {
    event.target.select();
  }

  selectItem(item: Item) {
    this.newItem.item = item;
    setTimeout(() => {
      document.getElementById('addItemButton').focus();
    });
  }

  preventEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  calculateSponsorship() {
    if (this.leadOrDonation?.discount?.valid && this.specificationToEdit) {
      this.sponsoredAmount = Pricing.calculateSponsoredAmount(
        this.specificationToEdit,
        this.leadOrDonation.pricing,
        this.leadOrDonation.discount,
        this.leadOrDonation.sponsorshipAlgorithm,
        this.donatableSmall
      );
    }

  }

  showSponsorshipPopup() {
    this.sponsorshipDialog = this.dialog.open(this.sponsorshipDialogTemplate, {
      width: '800px',
      maxWidth: '90vw'
    });
  }
}
