<div fxLayout="column" fxLayoutGap="0" fxFlex style="padding: 20px">
  <div>
    <h2>Great news!</h2>
    <p>
      We’re excited to inform you that our charity partners are kindly
      sponsoring certain eligible pickups to make them more affordable for our
      valued donors. Please note that your final sponsored quote will be
      determined on-site by your hauler, based on the quality and quantity of
      your donation items.
      <br />
      <br />
      Your donations are vital in helping those in need, and we truly appreciate
      your support. Thank you for making a difference!
    </p>
  </div>
  <div matDialogActions fxLayout="row" fxLayoutAlign="center center">
    <button
      class="primary-btn filled-btn"
      fxFlex="100"
      (click)="reference.close()"
      cdkFocusInitial
    >
      I Understand
    </button>
  </div>
</div>
