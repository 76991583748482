import { autoserializeAs } from 'cerialize';

export class PaymentDetails {
  @autoserializeAs('application_fee') applicationFee: number;
  @autoserializeAs('stripe_fee') stripeFee: number;
  @autoserializeAs('net_amount') netAmount: number;
  @autoserializeAs('total_amount') totalAmount: number;


  constructor(payload: IPaymentDetails) {
    this.applicationFee = payload?.applicationFee;
    this.stripeFee = payload?.stripeFee;
    this.netAmount = payload?.netAmount;
    this.totalAmount = payload?.totalAmount;
  }
}
export interface IPaymentDetails {
  applicationFee: number;
  stripeFee: number;
  netAmount: number;
  totalAmount: number;
}
